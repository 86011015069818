.cards {
  margin: 64px auto;

  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, 256px);
  grid-template-rows: repeat(6, auto);
  width: 1024px;
  max-width: 100%;

  overflow: hidden;
}
.cards a {
  /*color: inherit !important;*/
  text-decoration: none !important;
}

@media (max-width: 1024px) {
  .cards {
    grid-template-columns: repeat(3, 256px);
    grid-template-rows: repeat(8, auto);
    width: 768px;
  }
}
@media (max-width: 768px) {
  .cards {
    grid-template-columns: repeat(2, 256px);
    grid-template-rows: repeat(12, auto);
    width: 512px;
  }
}
@media (max-width: 512px) {
  .cards {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(24, auto);
    width: 100%;
  }
}

.cards .card {
  position: relative;
  z-index: 1;
  cursor: pointer;

  box-shadow:
		 /*17px     0 0 -16px var(--text),*/ -17px 0 0 -16px var(--text),
    /*0  17px 0 -16px var(--text),*/ 0 -17px 0 -16px var(--text);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 32px;
  text-align: left;

  --shadow-width: 4px;
}
.cards .card .number {
  margin-bottom: 0;
  width: 64px;
  height: 64px;
  line-height: 30px;
  text-align: left;
  font-style: italic;
  color: var(--text);
  text-shadow: var(--shadow-width) var(--shadow-width) var(--background);
}
.cards .card .spacer {
  flex-grow: 1;
}
.cards .card .image {
  margin-top: 16px;
  min-width: 128px;
  min-height: 128px;
  max-height: 256px;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  box-shadow: var(--shadow-width) var(--shadow-width) var(--background);
}
.cards .card .image img {
  width: 100%;
  opacity: 0;
}
.cards .card .weekday {
  color: var(--text);
  font-weight: bold;
  text-shadow: var(--shadow-width) var(--shadow-width) var(--background);
}
.cards .card .infoLine {
  display: flex;
  margin: 5px 0 1px 0;
  color: var(--text-body);
  transition: color 0.2s ease;
}
.cards .card .infoLine.address {
  margin-right: -22px;
}
.cards .card:hover .infoLine {
  color: var(--text);
}
.cards .card .infoLine svg {
  display: inline;
  flex-shrink: 0;
  vertical-align: top;
  margin: 1.5px 4px 0 0;
  fill: var(--text);
  transition: fill 0.2s ease;
}

.cards .card::after {
  z-index: -1;
  content: "";
  position: absolute;
  top: 16px;
  right: 16px;
  bottom: 16px;
  left: 16px;
  background: var(--inverted-background);

  transition: opacity 0.2s ease;
  opacity: 0;
}
.cards .card.active::after {
  opacity: 0.24;
}
.cards .card:hover::after {
  opacity: 0.12;
}

.cards .card .cardInner {
  height: 100%;
  width: 100%;
}
/* .cards .card .cardInner.isSunday::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 16px;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.25);
} */
